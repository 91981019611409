<template>
    <label class="CheckBox">
      <input 
        type="checkbox" 
        :checked="modelValue" 
        v-on:change="changeValue($event)">
      <span></span>
      <p v-html="text" v-on:click="$emit('text-clicked')"></p>
    </label>
  </template>

<script>
  export default {
    name: 'CheckBox',
    props: {
      modelValue: Boolean,
      text:{
        type:String,
        default:''
      }
    },
    emits: ['update:modelValue','change'],
    methods: {
      changeValue(event) {
        const newValue = event.target.checked; 
        this.$emit('update:modelValue', newValue);  
        this.$emit('change', newValue);  
      }
    }
  }
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css?family=Lato:400,700');

.CheckBox {
  display: flex;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 5px;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  span {
    position: absolute;
    top: 0;
    left: 0;
    height: 17px;
    width: 17px;
    border: 1px solid #E0E0E0;
    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }
  &:hover input ~ span {
    background-color: #ccc;
  }
  input:checked ~ span {
    background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
    border: none;
  }
  input:checked ~ span:after {
    display: block;
  }
  span:after {
    // content:"\f00c";
    // font-family: FontAwesome;
    // color: white;
    left: 5px;
    top: 1px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
  p{
    margin: -1px 0 0 29px;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.01em;
    color: #404040;
  }
</style>
