
  import { defineComponent } from 'vue';
  import moment from 'moment-timezone';
  import Input from '../Input/Input.vue'
  export default defineComponent({
  name : 'WatFocusTesterInfo',
  components:{
    Input
  },
  props:{
    provinces:{
      type: Array,
      default:() => []
    }
  },
  computed:{
    disabled(){
      return this.form.province.id == -1 || this.form.bornDate == null || this.form.bornDate < 18 || this.form.bornDate > 99 || this.form.nickname.length < 3 || this.form.gender == null || !this.isValidEmail()
    }
  },
  data(){
      return{
        form:{
          province:{
            id: -1
          },
          bornDate:null,
          nickname:'',
          gender:null,
          mail:''
        }
      }
    },
    methods:{
      isValidEmail(){
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(this.form.mail);
      },
      save(){
        this.form.bornDate = moment().subtract(this.form.bornDate, 'years').toDate();
        this.$emit('completed', this.form)
      }
    }
  })
  