
  import { defineComponent } from 'vue';
  import { cloneDeep } from 'lodash';
  import moment from 'moment-timezone';
  export default defineComponent({
  name : 'WatFocusTesterCalendar',

  props:{
    ranges:{
      type: Array,
      default: () => []
    },
    slots:{
      type:Array,
      default: () => []
    },
    sessionMinutes:{
      type:Number,
      default:30
    }
  },
  computed:{
    disabledButton(): boolean {
      return this.slotIds.length == 0
    }
  },
  data(){
    return{
      calendarDates:[{
        date:new Date(),
        ranges:[{from:"00:00",to:"00:00", id:0}],
        disabled: false
      }],
      availableHours:new Array(),
      slotIds:new Array()
    }
  },
  methods:{
    calendarBack(){
      const element: any = document.getElementById("WatFocusTesterCalendarDates")
      element.scrollLeft -=100
    },
    calendarNext(){
      const element: any = document.getElementById("WatFocusTesterCalendarDates")
      element.scrollLeft +=100
    },
    generateCalendar(){
      this.calendarDates = []
      const firstRange: any = this.ranges[0]
      let startDate = new Date();
      if(firstRange){
        let daysBetween = this.getDaysBetweenDates(startDate, new Date(firstRange.dateFrom))
        if(daysBetween > 0) daysBetween = 3
        startDate.setDate(startDate.getDate() + daysBetween);
      }
      else{
        startDate.setDate(startDate.getDate() + 3);
      }
      for (let i = 0; i <= 30; i++) {
        let date = this.getDate(startDate, i)
        const calendarDay = {
          date: date,
          ranges:[],
          disabled: this.isSunday(date)
        }
        this.calendarDates.push(calendarDay);
      }
    },
    isSunday(date: Date){
      return date.getDay() === 0;
    },
    sameDayDate(date1: Date, date2: Date){
      return date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate();
    },
    getDate(date: any, days: any){
      const newDate = new Date(date);
      newDate.setDate(newDate.getDate() + days);
      return newDate;
    },
    getDaysBetweenDates(date1: Date, date2: Date){
      const timeDifference = date2.getTime() - date1.getTime();
      const dayDifference = timeDifference / (1000 * 60 * 60 * 24);
      return Math.floor(dayDifference)
    },
    generateAvailableSessions() {
      this.availableHours = [
      "08:00","09:00","10:00","11:00","12:00","13:00","14:00","15:00","16:00","17:00","18:00","19:00","20:00"
      ]
        const sessions: any = [];
        const sessionTimeInMs = this.sessionMinutes * 60 * 1000;

        let [hours, minutes] = this.availableHours[0].split(":").map(Number);
        let startTime = new Date(0, 0, 0, hours, minutes);

        while (startTime.getHours() < 20 || (startTime.getHours() === 20 && startTime.getMinutes() === 0)) {
            let formattedTime = startTime.toTimeString().slice(0, 5);
            sessions.push(formattedTime);
            
            startTime = new Date(startTime.getTime() + sessionTimeInMs);
            if (startTime.getHours() >= 20 && startTime.getMinutes() > 0) break;
        }
        this.availableHours = sessions
    },
    isAvailableSlot(hour: any, calendarDay: any){
      return this.getSlot(hour, calendarDay) != null ? true : false
    },
    isSelectedSlot(hour: any, calendarDay: any){
      const slot: any = this.getSlot(hour, calendarDay)
      if(slot == null) return false
      return this.slotIds.includes(slot.id)
    },
    getSlot(hour: any, calendarDay: any){
      for(let slot of this.slots){
        const s: any = slot
        if(this.sameDayDate(calendarDay.date, new Date(s.dateFrom)) && moment(s.dateFrom).format("HH:mm") == hour){
          return slot
        }
      }
      return null
    },
    selectSlot(hour: any, calendarDay: any){
      const s: any = this.getSlot(hour, calendarDay)
      if(this.slotIds.includes(s.id)) this.slotIds.splice(this.slotIds.indexOf(s.id),1)
      else if(s != null) this.slotIds.push(s.id)
    }
  },
  mounted(){
    this.generateCalendar()
    this.generateAvailableSessions()
  }
  })
  